import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { get, isNumber } from 'lodash';
import '../styles/main.scss';

export interface HeaderProps {
  bannerHeight?: number;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title, bannerHeight }) => {
  const [showTitle, setShowTitle] = useState(!isNumber(bannerHeight));

  const handleScroll = (event: Event) => {
    const scrollTop = get(event, ['currentTarget', 'scrollY'], 0);
    setShowTitle(scrollTop > 400);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <header id="header" className={showTitle ? '' : 'alt'}>
      <h1>
        <Link to="/">
          {title}
        </Link>
      </h1>
      <nav id="nav" />
    </header>
  );
};

export default Header;
