import * as React from 'react';

export interface BannerProps {
  innerRef?: React.Ref<HTMLDivElement>;
}

const Banner: React.FC<BannerProps> = ({ children, innerRef }) => (
  <section id="banner">
    <div className="inner" ref={innerRef}>
      {children}
    </div>
    <a href="#" className="more scrolly">Learn More</a>
  </section>
);

export default Banner;
