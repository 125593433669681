import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { D51MediaLogoHorizontalSmall } from '@mechninja/mn-components';

import Header from './Header';
import Banner from './Banner';
import Helmet from 'react-helmet';
import SEO from './Seo';

export interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }: IProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  });

  useEffect(() => {
    const WebFont = require('webfontloader');
    WebFont.load({
      typekit: {
        id: 'uxp0mti',
      },
    });
  });

  const bannerRef = useRef(null);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          subtitle
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <Helmet>
        <title>Directorate 51</title>
        <link rel="preconnect" href="https://use.typekit.net" />
      </Helmet>
      <SEO
        title="Directorate 51"
      />
      <div id="page-wrapper" className={isLoaded ? '' : 'is-preload'}>
        <Header
          title={data.site.siteMetadata.title}
          bannerHeight={get(bannerRef, ['current', 'offsetHeight'], 0)}
        />
        <Banner innerRef={bannerRef}>
          <h2>{data.site.siteMetadata.title}</h2>
          <p>{data.site.siteMetadata.subtitle}</p>
        </Banner>
        <main>{children}</main>
        <footer id="footer">
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/directorate51hq"
                title="Twitter"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
          </ul>
          <ul className="copyright">
            <li>&copy; <a href="https://www.todddukart.com/">Todd Dukart</a></li>
            <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
            <li>
              <a
                href="https://www.d51media.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ border: 'none' }}
              >
                <D51MediaLogoHorizontalSmall style={{ opacity: .5 }} color="white" height={13} />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Layout;
